import "./App.css"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import "./assets/styles/style.scss"
import "./assets/static/fonts/Linearicons/Font/demo-files/demo.css"
import "./assets/static/fonts/font-awesome/css/font-awesome.min.css"
import "./assets/static/css/bootstrap.min.css"
import "./assets/static/css/slick.min.css"
import "./assets/styles/home-default.scss"
import "./assets/styles/market-place-1.scss"
import "./assets/styles/Custom.css"
import HomeMarketPlacePage from "./pages/home/market-place"
import Categories from "./pages/category/Category"
import ProductDetail from "./pages/product/ProductDetail"
import LoginPage from "./pages/account/login"
import RegisterPage from "./pages/account/register"
import CartView from "./pages/Cart/CartView"
import Checkout from "./pages/Cart/Checkout"
import Terms from "./pages/Terms&Conditions/Terms"
import ReturnPolicy from "./pages/Terms&Conditions/ReturnPolicy"
import PrivacyPolicy from "./pages/Terms&Conditions/PrivacyPolicy"
import ChainofCustody from "./pages/Terms&Conditions/ChainofCustody"
import Serach from "./pages/search/Search"
import Trafficking from "./pages/Terms&Conditions/Trafficking"
import Contact from "./pages/account/Contact"
import AboutUs from "./pages/account/AboutUs"
import Dashboard from "./pages/Dashboard-Panel/Dashboard"
import GuestCheckout from "./pages/account/GuestCheckout"
import StripeContainer from "./pages/Payment/StripeContainer"
import Customised from "./pages/Terms&Conditions/Customised"
import Career from "./pages/Terms&Conditions/Career"
import SpotDeals from "./pages/Offers/SpotDeals"
import Offers from "./pages/Offers/Offers"
import SpotDealsCategory from "./pages/Offers/SpotDealCategories"
import FAQ from "./pages/Terms&Conditions/FAQ"
import ProductNews from "./pages/Terms&Conditions/ProductNews"
import StockClearance from "./pages/Terms&Conditions/StockClearance"
import Enviornmental from "./pages/Terms&Conditions/Enviornmental"
import Services from "./pages/Terms&Conditions/Services"
import Forgot from "./pages/account/Forgot"
import Otp from "./pages/account/Otp"
import SecureLogin from "./pages/account/SecureLogin"
import NotFound from "./pages/NotFound"
import TawkToChat from "./pages/Tawkto" 
import PaymentSuccess from "./pages/Payment/PaymentSuccess"
import PaymentCancel from "./pages/Payment/PaymentCancel"
import Blog from "./pages/Blogs/blog"
import BlogDetails from "./pages/Blogs/BlogDetails"
import PaymentReceiptUpload from "./pages/Payment/PaymentReceiptUpload"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/login" Component={SecureLogin} /> */}
        <Route path="/login" Component={HomeMarketPlacePage} />
        <Route path="/" Component={HomeMarketPlacePage} />
        <Route path="/users/login" Component={LoginPage} />
        <Route path="/users/forgot-password" Component={Forgot} />
        <Route path="/users/otp-verification" Component={Otp} />
        <Route path="/users/registration" Component={RegisterPage} />
        <Route path="/shopping-cart" Component={CartView} />
        <Route path="/checkout" Component={Checkout} />
        <Route path="/terms-and-conditions" Component={Terms} />
        <Route path="/return-policy" Component={ReturnPolicy} />
        <Route path="/privacy-policy" Component={PrivacyPolicy} />
        <Route path="/chain-of-custody" Component={ChainofCustody} />
        <Route path="/slavery-human-trafficking" Component={Trafficking} />
        <Route path="/bespoke-machine-service" Component={Customised} />
        <Route path="/contact-us" Component={Contact} />
        <Route path="/about-us" Component={AboutUs} />
        <Route path="/search/" Component={Serach} />
        <Route path="/profile" Component={Dashboard} />
        <Route path="/users/guest-checkout" Component={GuestCheckout} />
        {/* <Route path="/payment" Component={StripeContainer} /> */}
        <Route path="/career" Component={Career} />
        <Route path="/spot-deals" Component={SpotDeals} />
        <Route path="/spot-deals-category" Component={SpotDealsCategory} />
        <Route path="/offers" Component={Offers} />
        <Route path="/faq" Component={FAQ} />
        <Route path="/product-news" Component={ProductNews} />
        <Route path="/stock-clearance" Component={StockClearance} />
        <Route path="/environmental-statement" Component={Enviornmental} />
        <Route path="/services" Component={Services} />
        <Route path="/success" Component={PaymentSuccess} />
        <Route path="/failed" Component={PaymentCancel} />
        <Route path="/product/:slug" Component={ProductDetail} />
        <Route path="/:slug" Component={Categories} />

        <Route path="/blogs" Component={Blog} />
        <Route path="/blog/:slug" Component={BlogDetails} />

        <Route path="/upload-payment-receipt/:order_id/:user_id" Component={PaymentReceiptUpload} />


        <Route path="*" Component={NotFound} />
      </Routes>
      <div className="ps-section__footer">
        <div>
          <TawkToChat />
        </div>
      </div>
    </BrowserRouter>
  )
}

export default App
