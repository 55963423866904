import React from 'react'
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import PageContainer from '../../components/layouts/PageContainer';
import BreadCrumb from '../../components/elements/BreadCrumb';
import picture1 from "../../assets/static/img/Picture1.png";
import picture2 from "../../assets/static/img/Picture2.png";
import picture3 from "../../assets/static/img/Picture3.png";
import { Helmet } from 'react-helmet';

const Enviornmental = () => {
    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Environmental Statement",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;
    return (
        <div>
            <Helmet>
                <title>Environmental Commitment by W-Hanson UK: Our Sustainable Practices</title>
                <meta name="description" Content="Explore how W-Hanson UK prioritizes sustainability in our operations and projects. Discover our environmental initiatives and commitments today." />

            </Helmet>
            <PageContainer header={headers} footer={footer} title="Home Market Place">
                <div className="ps-page--my-account">
                    <BreadCrumb breacrumb={breadCrumb} />
                    <div className="ps-section--custom">
                        <div className="container">
                            <div className="ps-section__header">
                                <div className="ps-section--vendors term-section bd-privacy">
                                    <div className="ps-section__header mb-0 pb-0 mt-20">
                                        <h3>Environmental Statement</h3>
                                    </div>
                                </div>
                                <div className="ps-section__content mt-70">
                                    <div className="ps-block--vendor-milestone">
                                        <div className="ps-block__left">
                                            <h4> Environment </h4>
                                            <p>
                                                The W.Hanson Group Ltd is committed to minimising the
                                                impact of its activities on the environment.
                                            </p>
                                            <p>
                                                The key points of our strategy to achieve this are:
                                            </p>
                                            <ul>
                                                <li>
                                                    Minimise waste by evaluating operations and ensuring
                                                    they are as efficient as possible.
                                                </li>
                                                <li>
                                                    Actively promote recycling both internally and
                                                    amongst its customers and suppliers.
                                                </li>
                                                <li>
                                                    Source and promote a product range to minimise the
                                                    environmental impact of both production and
                                                    distribution.
                                                </li>
                                                <li>
                                                    Meet or exceed all the environmental legislation
                                                    that relates to the Company.
                                                </li>
                                                <li>
                                                    Use an accredited program to offset the greenhouse
                                                    gas emissions generated by our activities.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="ps-block__right">
                                            <img src={picture1} height="290" loading="lazy" alt="No Img" />
                                        </div>
                                        <div className="ps-block__number">
                                            <span>1</span>
                                        </div>
                                    </div>
                                    <div className="ps-block--vendor-milestone reverse">
                                        <div className="ps-block__left">
                                            <h4>Environmental Statement</h4>
                                            <p>
                                                W.Hanson Group recognises the need for sustainable
                                                development and continually aims to improve the
                                                environmental effect of its activities, to acheive
                                                this we will:
                                            </p>
                                            <p>Establish sound environmental management by:-</p>
                                            <ul>
                                                <li>
                                                    Meeting or improving upon relevant legislative,
                                                    regulatory and environmental codes of practice.
                                                </li>
                                                <li>
                                                    Developing objectives that target environmental
                                                    improvements and monitor performance by regular
                                                    review.
                                                </li>
                                                <li>
                                                    Considering any environmental issues in the
                                                    decision-making process.
                                                </li>
                                                <li>
                                                    Developing a relationship with suppliers and
                                                    contractors so that we all recognise our
                                                    environmental responsibilities.
                                                </li>
                                                <li>
                                                    Educating staff so that they carry out their
                                                    activities in an environmentally responsible manner.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="ps-block__right">
                                            <img
                                                src={picture2}
                                                height="290"
                                                width="350"
                                                loading="lazy" alt="No Img"
                                            />
                                        </div>
                                        <div className="ps-block__number">
                                            <span>2</span>
                                        </div>
                                    </div>
                                    <div className="ps-block--vendor-milestone">
                                        <div className="ps-block__left">
                                            <p>Provide for the effective use of resources by:-</p>
                                            <ul>
                                                <li>
                                                    Promoting waste minimisation by recycling or finding other uses for by-products whenever environmentally viable.
                                                </li>
                                                <li>
                                                    Promoting the efficient use of resources, energy & fuel throughout the organization's operations.
                                                </li>
                                                <li>Considering any environmental issues in the decision-making process.</li>
                                                <li>Developing a relationship with suppliers and contractors so that we all recognise our environmental responsibilities.</li>
                                                <li>Educating staff so that they carry out their activities in an environmentally responsible manner.</li>
                                            </ul>
                                            <p>Co-operate with:-</p>
                                            <ul>
                                                <li>The communities in which we operate.</li>
                                                <li>The Government, regulatory bodies and other interested parties with the shared vision of being a good and trusted neighbour.</li>
                                            </ul>
                                        </div>
                                        <div className="ps-block__right">
                                            <img src={picture3} height="290" loading="lazy" alt="No Img" />
                                        </div>
                                        <div className="ps-block__number">
                                            <span>3</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default Enviornmental