import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageContainer from "../../components/layouts/PageContainer";
import BreadCrumb from "../../components/elements/BreadCrumb";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import { Input, Modal } from "antd";
import logo from "../../assets/static/img/whanson-logo.png";
import swal from "sweetalert";
import TawkToChat from "../Tawkto";
import config from "../../config";
import axios from "axios";
import Loader from "../Loader";

const CartView = () => {
  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("auth_user")
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);

  const handleClick = () => {
    if (window.Tawk_API) {
      window.Tawk_API.toggle(); // Tawk.to chat widget toggle
    }
  };

  const [isClicked, setIsClicked] = useState(false);
  const [quantity, setQuantity] = useState([]);
  const [data, setData] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [store_data, setStoreData] = useState([]);
  const [isNegotiate, setIsNegotiate] = useState(false);
  const [negotiated_price, setNegotiatedPrice] = useState(0);

  const [negotiateCart, setNegotiateCart] = useState(0);
  const [nonNegotiateCart, setNonNegotiateCart] = useState(0);

  useEffect(() => {
    setData(JSON.parse(localStorage.getItem("items")));
    setClicked(false);

    setQuantity(
      Array.from(
        { length: JSON.parse(localStorage.getItem("items"))?.length },
        () => 1
      )
    );
  }, [clicked]);

  useEffect(() => {
    const getCartData = async () => {
      const formData = new FormData();
      setLoading(true);
      formData.append("cart_id", localStorage.getItem("cart_id"));
      try {
        const res = await axios.post(`${config.apiUrl}/cartDetails`, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });

        setStoreData(res.data.data?.cartData);
        setIsNegotiate(res.data.data.negotiated);
        setNegotiatedPrice(
          res.data.data.negotiated == 1 ? res.data.data.price : 0
        );
        const Items = JSON.parse(localStorage.getItem("items"));
        const Negotiableprice = Items?.filter((obj) => obj.negotiate == false)
          .flat()
          .reduce((total, item) => {
            return total + parseFloat(item.price) * item.qty;
          }, 0);
        setNegotiateCart(Negotiableprice);
        setNonNegotiateCart(nonNegotiableprice);
        let existingIds = Items.map((obj) => obj.id);
        // Filter array2 to get objects with ids not present in array
        let output = res.data.data.cartData?.filter(
          (obj) => !existingIds.includes(obj.product_id)
        );

        console.log("output", output)

        const updatedArray = output.map((item) => ({
          id: item.product_id,
          name: item.name,
          negotiate: false, // Assuming this is a default value
          price: item.price,
          qty: item.qty,
          width: item.width,
          height: item.height,
          length: item.length,
          thumbnail_image: item.photos,
          category_id: item.category_id,
        }));
        const updatedItems = [...Items, ...updatedArray];
        if (output?.length != 0) {
          localStorage.setItem("items", JSON.stringify(updatedItems));
          const Negotiableprice = updatedItems
            ?.filter((obj) => obj.negotiate == false)
            .flat()
            .reduce((total, item) => {
              return total + parseFloat(item.price) * item.qty;
            }, 0);
          setNegotiateCart(Negotiableprice);
        }
        setClicked(true);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (localStorage.getItem("cart_id") != null) {
      getCartData();
    }
  }, []);

  const items = data?.map((item, i) => {
    const Total = parseFloat(item.price) * item.qty;
    return (
      <tr key={item.id} className={item.negotiate ? "bg-nego-light-blue" : ""}>
        <td>
          <div className="row align-items-center">
            <div className="col-3 text-center">
              <Link to={`/product/${item.slug}`}>
                <img
                  src={
                    item.thumbnail_image != null ? item.thumbnail_image : logo
                  }
                  height="70"
                />
              </Link>
            </div>
            <div className="col-9 pl-0 pr-0">
              {item.name}
              {item.type == "Linear" ? (
                <p className="varition-size">
                  {" "}
                  H : {item.height}mm , W : {item.width}mm , L : {item.length}m{" "}
                </p>
              ) : null}
              {item.negotiate ? (
                <p className="text-danger " style={{ fontSize: "11px" }}>
                  * Non Negotiable Product as cart is Already Negotiated
                </p>
              ) : null}
            </div>
          </div>
        </td>
        <td data-label="price" className="price">
          £{item.price}
        </td>
        <td data-label="quantity">
          <div className="form-group--number">
            <button
              className="up"
              onClick={() => {
                if (localStorage.getItem("cart_id") != null) {
                  swal({
                    icon: "warning",
                    text: "Quantity won't be increase as cart is already negotiated at that Amount",
                  });
                } else {
                  const updatedArray = data.map((obj, index) => {
                    if (index === i) {
                      return { ...obj, qty: item.qty + 1, zip_status: 0 };
                    }
                    return obj;
                  });
                  localStorage.setItem("items", JSON.stringify(updatedArray));
                  setClicked(true);
                }
              }}
            >
              +
            </button>
            <button
              className="down"
              onClick={() => {
                if (localStorage.getItem("cart_id") != null) {
                  swal({
                    icon: "warning",
                    text: "Quantity won't be decrease as cart is already negotiated at that Amount",
                  });
                } else {
                  if (item.qty > 1) {
                    const updatedArray = data.map((obj, ind) => {
                      if (ind === i) {
                        return { ...obj, qty: item.qty - 1, zip_status: 0 };
                      }
                      return obj;
                    });
                    localStorage.setItem("items", JSON.stringify(updatedArray));
                    setClicked(true);
                  }
                }
              }}
            >
              -
            </button>
            <input
              className="form-control"
              type="text"
              value={item.qty}
              disabled={true}
            />
          </div>
        </td>
        <td data-label="total">
          <strong>£{Total?.toFixed(2)}</strong>
        </td>
        <td className="text-center">
          <a
            className="btn-trash bg-danger"
            onClick={() => {
              swal({
                icon: "warning",
                title: "Are You sure ?",
                text: "You want to remove this item from Cart",
                buttons: true,
              }).then(async (willDelete) => {
                if (willDelete) {
                  setLoading(true);
                  const isDelete = data.filter((obj) => obj.id != item.id);
                  const totalPrice = isDelete.flat().reduce((total, item) => {
                    return total + parseFloat(item.price) * item.qty;
                  }, 0);
                  const formData = new FormData();
                  formData.append("cart_id", localStorage.getItem("cart_id"));
                  formData.append("product_id", item.id);
                  if (isNegotiate != 1) {
                    formData.append("total_price", totalPrice);
                  }
                  try {
                    const res = await axios.post(
                      `${config.apiUrl}/removeCart`,
                      formData,
                      {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    );
                    setLoading(false);
                    setClicked(true);
                    const items = data.filter((obj, ind) => ind != i);
                    const updatedItems = items.map((item) => ({
                      ...item,
                      zip_status: 0,
                    }));
                    if (localStorage.getItem("ship") == null) {
                      localStorage.setItem("items", JSON.stringify(items));
                    } else {
                      localStorage.setItem("items", JSON.stringify(updatedItems));
                    }
                  } catch (error) {
                    console.log(error);
                    setLoading(false);
                  }
                }
              });
            }}
          >
            <i className="icon-trash2"></i>
          </a>
        </td>
      </tr>
    );
  });

  const totalPrice = JSON.parse(localStorage.getItem("items"))
    ?.flat()
    .reduce((total, item) => {
      return total + parseFloat(item.price) * item.qty;
    }, 0);

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Shopping Cart",
    },
  ];
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  const [open, setOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const onRedirect = () => {
    setOpen(false);
    if (
      !localStorage.getItem("auth_id") &&
      !localStorage.getItem("auth_name")
    ) {
      setLoginModal(true);
    } else {
      navigate("/checkout");
    }
  };

  const navigate = useNavigate();

  // Negotaition Functionality

  const [negotiate, setNegotiate] = useState(false);
  const [enquiry, setEnquiry] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [price, setPrice] = useState("");
  const [message, setMessage] = useState("");
  const [hide_quest, setHideQuest] = useState(true);
  const [reNegotiate, setReNegotiate] = useState(false);
  const [method, setMethod] = useState("");

  const onCardStore = async () => {
    const data = JSON.parse(localStorage.getItem("items"));
    if (method == "enquiry") {
      setEnquiry(true);
      setNegotiate(false);
      setReNegotiate(false);
    } else {
      setLoading(true);
      const totalPrice = data.flat().reduce((total, item) => {
        return total + parseFloat(item.price) * item.qty;
      }, 0);
      const formData = new FormData();
      const ProductIds = data.map((obj) => obj.id).join(",");
      const Price = data.map((obj) => obj.price).join(",");
      const Qty = data.map((obj) => obj?.qty).join(",");
      const Widths = data.map((obj) => obj.width).join(",");
      const Heights = data.map((obj) => obj.height).join(",");
      const Lengths = data.map((obj) => obj?.length).join(",");
      formData.append("user_id", localStorage.getItem("auth_id"));
      formData.append("product_id", ProductIds);
      formData.append("price", Price);
      formData.append("quantity", Qty);
      formData.append("height", Heights);
      formData.append("width", Widths);
      formData.append("length", Lengths);
      formData.append("total_price", totalPrice);
      try {
        const res = await axios.post(`${config.apiUrl}/addToCart`, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
        if (method == "enquiry") {
          localStorage.setItem("cart_id", res.data.data);
          setEnquiry(true);
          setNegotiate(false);
        } else if (method == "chat") {
          setIsClicked(true);
          setNegotiate(false);
          setTimeout(() => {
            setIsClicked(false);
          }, 5000);
          localStorage.removeItem("items");
          navigate("/profile?tab=cart&type=chat");
        } else {
          setNegotiate(false);
          localStorage.removeItem("items");
          navigate("/profile?tab=cart");
        }
        const total = JSON.parse(localStorage.getItem("items"))
          ?.flat()
          .reduce((total, item) => {
            return total + parseFloat(item.price) * item.qty;
          }, 0);
        setNegotiateCart(total);
        setReNegotiate(false);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const onEnquiryForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const totalPrice = data.flat().reduce((total, item) => {
      return total + parseFloat(item.price) * item.qty;
    }, 0);
    const formData = new FormData();
    const ProductIds = data.map((obj) => obj.id).join(",");
    const Price = data.map((obj) => obj.price).join(",");
    const Qty = data.map((obj) => obj?.qty).join(",");
    const Widths = data.map((obj) => obj.width).join(",");
    const Heights = data.map((obj) => obj.height).join(",");
    const Lengths = data.map((obj) => obj?.length).join(",");
    formData.append("user_id", localStorage.getItem("auth_id"));
    formData.append("product_id", ProductIds);
    formData.append("price", Price);
    formData.append("quantity", Qty);
    formData.append("height", Heights);
    formData.append("width", Widths);
    formData.append("length", Lengths);
    formData.append("total_price", totalPrice);
    try {
      const res = await axios.post(`${config.apiUrl}/addToCart`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      const formDatas = new FormData();
      formDatas.append("user_id", localStorage.getItem("auth_id"));
      formDatas.append("cart_id", res.data.data);
      formDatas.append("price", price);
      formDatas.append("message", message);
      try {
        const ress = await axios.post(
          `${config.apiUrl}/create_inquiry`,
          formDatas,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (ress.data.success == true) {
          swal({
            icon: "success",
            title: "Enquiry sent Successfully",
            text: `The Admin will contact you shortly. Your Enquiry ID is ${ress.data.data}.`,
          }).then((data) => {
            setEnquiry(false);
            localStorage.removeItem("items");
            localStorage.removeItem("cart_id");
            navigate("/profile?tab=cart");
          });
        } else {
          swal({
            icon: "warning",
            text: `Enquiry is Already sent for this cart Price`,
          }).then((data) => {
            setEnquiry(false);
          });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const nonNegotiableprice = JSON.parse(localStorage.getItem("items"))
    ?.filter((obj) => obj.negotiate == true)
    .flat()
    .reduce((total, item) => {
      return total + parseFloat(item.price) * item.qty;
    }, 0);

  const isnonNegotiate = JSON.parse(localStorage.getItem("items"))?.some(
    (item) => item.negotiate
  );

  const onEmptyCart = () => {
    swal({
      icon: "warning",
      title: "Are You sure ?",
      text: "You want to Empty your Cart",
      buttons: true,
    }).then((data) => {
      if (data) {
        setLoading(true);
        localStorage.setItem("items", "[]");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("order_id");
        localStorage.removeItem("ship");
        localStorage.removeItem("auth_users");
        localStorage.removeItem("congestion");
        setData([]);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  return (
    <div>
      {isLoading && <Loader />}
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--my-account">
          <BreadCrumb breacrumb={breadCrumb} />
          <div className="mb-70 mt-70 bg-white">
            <div className="container">
              <div className="d-flex justify-space-between mb-10">
                <div>
                  {localStorage.getItem("cart_id") != null ? (
                    <h2 className="mb-15">
                      Your Cart ID : CRT0{localStorage.getItem("cart_id")}
                    </h2>
                  ) : null}
                </div>
                <div className="">
                  {data?.length != 0 ? (
                    <a className="empty-cart" onClick={onEmptyCart}>
                      <i className="icon-cart-empty ms-2"></i>
                      <span className="pd-text">Empty Your Cart</span>
                    </a>
                  ) : null}
                </div>
              </div>
              <div className="ps-section__content">
                <table className="table  ps-table--shopping-cart ps-table--responsive">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items}
                    {data?.length == 0 || data == null ? (
                      <td colSpan="6">
                        <p className="text-center mt-20">No Product in Added</p>
                      </td>
                    ) : null}
                  </tbody>
                </table>
                <div>
                  <h2
                    className="text-right pt-10 pr-3 d-flex justify-content-end"
                    style={{ borderTop: "1px solid #07395c" }}
                  >
                    <div className="row  bd-font-cart" style={{ width: "35%" }}>
                      <div className="col-6 mb-10">
                        {localStorage.getItem("cart_id") != null
                          ? "Negotiate Cart"
                          : "Sub Total"}
                      </div>
                      <div className="col-6 mb-10">
                        £
                        {localStorage.getItem("cart_id") != null ? (
                          <>{parseFloat(negotiateCart).toFixed(2)}</>
                        ) : (
                          <>{parseFloat(totalPrice).toFixed(2)}</>
                        )}
                      </div>
                      {isNegotiate ? (
                        <>
                          <div className="col-6 mb-10">Discount</div>
                          <div className="col-6 mb-10">
                            £
                            {parseFloat(
                              parseFloat(negotiateCart) -
                              parseFloat(negotiated_price)
                            ).toFixed(2)}
                          </div>

                          {isnonNegotiate && (
                            <>
                              <div className="col-6 mb-10">
                                Non Negotiated Cart
                              </div>
                              <div className="col-6 mb-10">
                                £{parseFloat(nonNegotiableprice).toFixed(2)}
                              </div>
                            </>
                          )}
                          <div className="col-6 mb-10 pt-10 boder-top">
                            <strong>Total</strong>
                          </div>
                          <div className="col-6 mb-10 pt-10 boder-top">
                            <strong>
                              £
                              {(
                                parseFloat(negotiateCart) -
                                (parseFloat(negotiateCart) -
                                  parseFloat(negotiated_price)) +
                                parseFloat(nonNegotiableprice)
                              ).toFixed(2)}{" "}
                            </strong>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </h2>
                </div>
                <div className="row justify-space-between mt-30">
                  <div className="ps-section__cart-actions pl-20">
                    <a onClick={() => navigate(-1)}>
                      <i className="icon-arrow-left mr-2"></i>Return to shop
                    </a>
                  </div>
                  <div className="row justify-content-end col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-right">
                      <a
                        onClick={() => {
                          if (totalPrice <= 1000) {
                            swal({
                              icon: "warning",
                              text: "Price must be Greater than £1000 to Access this Feature.",
                            });
                          } else if (localStorage.getItem("auth_id") === null) {
                            setLoginModal(true);
                            setHideQuest(false);
                          } else if (
                            localStorage.getItem("auth_type") === "guest"
                          ) {
                            swal({
                              icon: "warning",
                              text: "This Feature is not Available for Guest Users.",
                            });
                          } else if (localStorage.getItem("cart_id") != null) {
                            swal({
                              icon: "warning",
                              text: "Negotiation is Already Done for this Cart",
                            });
                          } else {
                            setNegotiate(true);
                          }
                        }}
                        className="ps-btn  bg-light-blue color-white "
                        style={{
                          padding: "15px 30px",
                          backgroundColor: "#1d87d3",
                        }}
                      >
                        Want to Negotiate Price ?
                      </a>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                      <a
                        onClick={() => setOpen(true)}
                        className="ps-btn ps-btn--fullwidth bg-blue color-white"
                        style={{ fontSize: "15px" }}
                      >
                        Proceed to Checkout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ps-section__footer">
                <div className={isClicked ? "animate" : ""}>
                  <TawkToChat handleClick={handleClick} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>

      <Modal
        open={open}
        title="Rules of Refund"
        centered
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={<div className="footer-none"></div>}
      >
        {/* <div className="mb-5 py-2 head_line px-3" style={{ fontSize: "15px" }}>
          We are closed, and orders will not be processed until we return on 2nd January 2025.
        </div> */}
        <ol className="pl-20  mb-30">
          <li className="mb-10">
            Products marked as "non-returnable" on the product detail page
            cannot be returned.
          </li>
          <li className="mb-10">
            No additional information is required to return an eligible order
            unless otherwise noted in the category-specific policy.
          </li>
          <li className="mb-10">
            Products may be eligible for replacement only if the same seller has
            the exact same item in stock.
          </li>
          <li className="mb-10">
            If the replacement request is placed and the seller does not have
            the exact same product in stock, a refund would be issued to you.
          </li>
        </ol>
        <div className="text-center mb-20 ">
          <a
            className="btn-ligth-blue p-4 mr-10"
            onClick={() => setOpen(false)}
          >
            Back to Shopping
          </a>
          <a onClick={onRedirect} className="btn-blue p-4 ml-10">
            Proceed to Checkout
          </a>
        </div>
      </Modal>

      <Modal
        open={loginModal}
        centered
        onOk={() => setLoginModal(false)}
        onCancel={() => setLoginModal(false)}
        footer={<div className="footer-none"></div>}
        header={<></>}
        width={400}
      >
        <div className="text-center mb-20">
          <img src={logo} className="" style={{ width: "50%" }} loading="lazy" alt="No img" />
        </div>
        <div className="text-center mb-20 ">
          {hide_quest && (
            <div className="mb-20">
              <button
                className="btn-ligth-blue p-4  w-100"
                onClick={() => {
                  navigate("/users/guest-checkout");
                }}
                style={{ border: "none" }}
              >
                <i
                  className="icon-user pr-10"
                  style={{ fontSize: "17px", lineHeight: "0" }}
                ></i>
                Continue with Guest User
              </button>
            </div>
          )}
          <div>
            <button
              onClick={() => {
                navigate("/users/login");
              }}
              className="btn-blue p-4  w-100"
              style={{ border: "none" }}
            >
              <i
                className="icon-user-lock pr-10"
                style={{ fontSize: "17px", lineHeight: "0" }}
              ></i>
              Continue with Login
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={negotiate}
        centered
        onOk={() => setNegotiate(false)}
        onCancel={() => setNegotiate(false)}
        footer={<div className="footer-none"></div>}
        header={<></>}
        width={450}
      >
        <div className="text-center mb-20">
          <img src={logo} className="" style={{ width: "50%" }} loading="lazy" alt="No img" />
        </div>
        <div className="text-center mb-20 ">
          <div className="mb-20">
            <button
              className="btn-ligth-blue p-4  w-100"
              onClick={() => {
                setReNegotiate(true);
                setMethod("enquiry");
              }}
              style={{ border: "none" }}
            >
              <i
                className="icon-files pr-10"
                style={{ fontSize: "17px", lineHeight: "0" }}
              ></i>
              Fill the Enquiry Form for Negotiate
            </button>
          </div>
          <div className="mb-20">
            <button
              onClick={() => {
                setMethod("chat");
                setReNegotiate(true);
              }}
              className="btn-blue p-4  w-100"
              style={{ border: "none" }}
            >
              <i
                className="icon-bubbles pr-10"
                style={{ fontSize: "17px", lineHeight: "0" }}
              ></i>
              Negotiate Using Chat with Us
            </button>
          </div>
          <div>
            <a
              // href="tel:020 8864 0822 1898"
              className="btn-blue bg-light-blue text-white p-4 d-block  w-100"
              style={{ border: "none" }}
              onClick={() => {
                setReNegotiate(true);
                setMethod("call");
              }}
            >
              <i
                className="icon-phone-wave pr-10"
                style={{ fontSize: "17px", lineHeight: "0" }}
              ></i>
              Call us on{" "}
              <span style={{ color: "#07395c", fontWeight: "600" }}>
                020 8864 0822
              </span>{" "}
              for Negotiate
            </a>
          </div>
        </div>
      </Modal>

      <Modal
        open={enquiry}
        title="Enquiry for Negotiation"
        centered
        onOk={() => setEnquiry(false)}
        onCancel={() => setEnquiry(false)}
        footer={<div className="footer-none"></div>}
        width={400}
      >
        <div>
          <form className="ps-form--account pt-0" onSubmit={onEnquiryForm}>
            <div className="">
              <h3 className="mb-4">Cart Price : £ {totalPrice?.toFixed(2)}</h3>
              <div className="form-group form-forgot mb-4">
                <label className="mb-3">Negotiate Price</label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Enter Negotiate Price"
                  required
                  value={price}
                  onChange={(e) => {
                    const regex = /^\d*\.?\d*$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setPrice(e.target.value);
                    }
                  }}
                />
              </div>
              <div className="form-group form-forgot">
                <label className="mb-3">Message</label>
                <textarea
                  className="form-control p-3"
                  type="email"
                  placeholder="Email Message"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <button
                className="ps-btn w-100 text-center  bg-blue color-white "
                style={{ padding: "15px 30px" }}
              >
                Ask for Negotiation
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        open={reNegotiate}
        title="Rules of Negotiation"
        centered
        onOk={() => setReNegotiate(false)}
        onCancel={() => setReNegotiate(false)}
        footer={<div className="footer-none"></div>}
      >
        <ol className="pl-20  mb-30">
          <li className="mb-10">
            The added product will not be negotiable since items in the cart
            have alr eady been negotiated. To negotiate this product, please
            create a new order !
          </li>
          <li className="mb-10 text-danger">
            This exclusive offer of Negotiation is valid for 3 days only.
          </li>
        </ol>
        <div className="text-center mb-20 ">
          <a
            className="btn-ligth-blue p-4 mr-10"
            onClick={() => setReNegotiate(false)}
          >
            Back to Shopping
          </a>
          <a
            onClick={() => {
              if (localStorage.getItem("cart_id") != null) {
                handleClick();
                setReNegotiate(false);
                setNegotiate(false);
              } else {
                onCardStore();
              }
            }}
            className="btn-blue p-4 ml-10"
          >
            Proceed to Negotiation
          </a>
        </div>
      </Modal>
    </div>
  );
};

export default CartView;
