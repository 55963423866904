import React, { useEffect, useState } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import BreadCrumb from "../../components/elements/BreadCrumb";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import { Link, useNavigate } from "react-router-dom";
import { Input, notification } from "antd";
import axios from "axios";
import Loader from "../../pages/Loader";
import config from "../../config";
import { Helmet } from "react-helmet";

const RegisterPage = () => {
  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("auth_user")
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Register an account",
    },
  ];
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPass] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [show_password, setShowPassword] = useState(false);
  const [show_password2, setShowPassword2] = useState(false);

  const [error, setError] = useState("");
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const navigate = useNavigate();

  const onHandleRegister = async (e) => {
    e.preventDefault();
    if (emailRegex.test(email)) {
      setError('')
      if (confirm_password == password) {
        setLoading(true);
        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);
        formData.append("mobile", mobile);
        formData.append("name", name);
        formData.append("type", 0);
        try {
          const response = await axios({
            method: "post",
            url: `${config.apiUrl}/auth/signup`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then((response) => {
            if (response.data.result == false) {
              notification.open({
                message: (
                  <div className="d-flex">
                    <div className="pr-20">
                      <i
                        className="fa fa-exclamation-circle text-danger"
                        style={{ color: "#4bb543" }}
                      ></i>
                    </div>
                    <div>User Already Exist</div>
                  </div>
                ),
                description: "Please try using different Email Address",
              });
            } else {
              notification.open({
                message: (
                  <div className="d-flex">
                    <div className="pr-20">
                      <i
                        className="fa fa-check"
                        style={{ color: "#4bb543" }}
                      ></i>
                    </div>
                    <div>Registration Done Successfully</div>
                  </div>
                ),
                description: "Please Login using same Credentials",
              });
              navigate("/users/login");
            }
            setLoading(false);
          });
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      } else {
        notification.open({
          message: (
            <div className="d-flex">
              <div className="pr-20">
                <i
                  className="fa fa-exclamation-circle text-danger"
                  style={{ fontSize: "20px" }}
                ></i>
              </div>
              <div>Opp! Something went wrong.</div>
            </div>
          ),
          description: "Please Enter Same Password",
        });
      }
    } else {
      setError("Please enter a valid email address.");
    }
  };

  return (
    <>
      <Helmet>
        <title>W-Hanson UK’s Registration Portal</title>
        <meta
          name="description"
          content="Register hassle-free at W-Hanson UK to access exclusive benefits. Join now and experience top-tier service and support."
        />
      </Helmet>
      {isLoading && <Loader />}
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--my-account">
          <BreadCrumb breacrumb={breadCrumb} />

          <div className="mb-70 mt-70 bg-white">
            <div className="container">
              <form
                className="ps-form--account pt-0"
                onSubmit={onHandleRegister}
              >
                <div className="ps-tab active border-radius3 bd-login-page">
                  <div className="ps-form__content p-5 ">
                    <h2 className="pb-20 text-center">Create An Account</h2>
                    <div className="form-group form-forgot">
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Enter Full Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group form-forgot">
                      <Input
                        className="form-control"
                        type="email"
                        placeholder="Enter Email Address"
                        value={email}
                        onChange={handleEmailChange}
                        required
                      />
                      {error && <span className="text-danger">{error}</span>}
                    </div>
                    <div className="form-group form-forgot">
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Enter Phone Number"
                        minLength={7}
                        maxLength={15}
                        value={mobile}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          setPhone(numericValue);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group form-forgot ">
                      <div className="position-relative">
                        <Input
                          className="form-control"
                          type={show_password ? "text" : "password"}
                          placeholder="Enter Password"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <div className="bd-eye-open">
                          <a onClick={() => setShowPassword(!show_password)}>
                            <i
                              className={
                                show_password ? "fa fa-eye" : "fa fa-eye-slash"
                              }
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="form-group form-forgot ">
                      <div className="position-relative">
                        <Input
                          className="form-control"
                          type={show_password2 ? "text" : "password"}
                          placeholder="Enter Confirm Password"
                          onChange={(e) => setConfirmPass(e.target.value)}
                          required
                        />
                        <div className="bd-eye-open">
                          <a onClick={() => setShowPassword2(!show_password2)}>
                            <i
                              className={
                                show_password2 ? "fa fa-eye" : "fa fa-eye-slash"
                              }
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="form-group pb-20">
                      <div className="ps-checkbox">
                        <input
                          className="form-control"
                          type="checkbox"
                          id="remember-me"
                          name="remember-me"
                          required
                        />
                        <label htmlFor="remember-me">
                          By signing up you agree to our terms and conditions.
                        </label>
                      </div>
                    </div>
                    <div className="form-group submit">
                      <button
                        type="submit"
                        className="ps-btn ps-btn--fullwidth bg-blue color-white"
                      >
                        Register Now
                      </button>
                    </div>
                    <div className="mt-20 text-center">
                      <p className="mb-0">Already have an account?</p>
                      <Link
                        to="/users/login"
                        className="color-blue"
                        style={{ fontWeight: "600" }}
                      >
                        Log In
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  );
};

export default RegisterPage;
