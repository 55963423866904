import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import NextArrow from "../../components/elements/carousel/NextArrow";
import PrevArrow from "../../components/elements/carousel/PrevArrow";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import axios from "axios";
import Loader from "../Loader";
import NoImg from "../../assets/static/img/w-logo.jpg"
import { notification } from "antd";
import LightBoxicon from "../category/LightBox";
import config from "../../config";

const HomeDefaultDealOfDay = () => {


  const carouselFullwidth = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    slidesToShow: 5,
    slidesToScroll: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    fetchDryWallProduc()
  }, [])

  const [isLoading, setLoading] = useState(false)
  const [product_list, setProductList] = useState([])
  const fetchDryWallProduc = async () => {
    const formData = new FormData();
    formData.append('page', 0)
    formData.append('pageSize', 30)
    setLoading(true)
    try {
      await axios({
        method: "post",
        data:formData,
        url: `${config.apiUrl}/products/category/Drywall-Products`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setProductList(res.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const [itemsInStorage, setItemsInStorage] = useState(
    JSON.parse(localStorage.getItem("items")) || []
  );

  return (
    <div>
      {isLoading && (<Loader />)}
      <div className="ps-deal-of-day">
        <div className="container">
          <div className="ps-section__header home4-header-prod">
            <h3>Drywall Products </h3>
            <div>
              <Link to="/Drywall-Products" style={{ color: "black" }}>View All </Link>
            </div>
          </div>
          <div>
            <Slider {...carouselFullwidth} className="ps-carousel outside">
              {product_list.map((items, i) => {
                const stars = [];
                for (let i = 1; i <= 5; i++) {
                  const className = i <= items.rating ? 'fa fa-star' : 'fa fa-star-o';
                  stars.push(
                    <i className={className} key={i}></i>
                  );
                }

                const newArray = [items]

                const onAddCart = (e) => {
                  let updatedItems;
                  if (
                    itemsInStorage.some(item => item.id === items.id) == true
                  ) {
                    notification.open({
                      message: (
                        <div className="d-flex">
                          <div className="pr-20">
                            <i
                              className="fa fa-exclamation-circle text-danger"
                              style={{ fontSize: "20px" }}
                            ></i>
                          </div>
                          <div>Product Already Added</div>
                        </div>
                      ),
                      description: "You can increase its Quantity",
                    });
                  } else {
                    updatedItems = [...itemsInStorage, newArray[0]];
                    localStorage.setItem(
                      "items",
                      JSON.stringify(updatedItems)
                    );
                    setItemsInStorage(updatedItems);
                    notification.open({
                      message: (
                        <div className="d-flex">
                          <div className="pr-20">
                            <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
                          </div>
                          <div>Product Added to Cart</div>
                        </div>
                      ),
                      description: "You can view it in the cart.",
                    });
                  }
                }


                return (
                  <div key={i} className="ps-section__content">
                    <div className="ps-product dd" key={i} style={{ width: "236px", height: '360px' }}>
                      <div className="ps-product__thumbnail">
                        <Link to={`/product/${items.slug} `} >
                          <a>
                            <LazyLoad>
                              <img
                                src={items.thumbnail_image != null ? items.thumbnail_image : NoImg}
                                loading="lazy" alt='No img'
                                height='198'
                                style={{ objectFit: "cover" }}
                              />
                            </LazyLoad>
                          </a>
                        </Link>
                        <LightBoxicon productId={items.id} />
                      </div>
                      <div className="ps-product__container">
                        <div className="ps-product__content" style={{ height: '90px' }}>
                          <Link to={`/product/${items.slug} `} style={{ color: "black" }}>
                            {items.name}
                          </Link>
                          <div className="ps-product__rating">
                            <span className="ps-rating">
                              {stars}
                            </span>

                            <span>{items.rating}</span>
                          </div>
                          <div className="">
                            <p className="ps-product__price sale">
                              £ {parseFloat(items.price).toFixed(2)}
                              <del className="ml-10">
                                {items.stroked_price}
                              </del>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>

        </div>
      </div >
    </div >
  );
};

export default HomeDefaultDealOfDay;
