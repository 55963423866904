import React from "react";
import { Link } from "react-router-dom";
import Logo1 from "../../assets/static/img/category/Softwood 1.jpg";
import Logo2 from "../../assets/static/img/category/mdf.jpg";
import Logo3 from "../../assets/static/img/category/insulation.jpg";
import Logo4 from "../../assets/static/img/category/sheet-material.jpg";
import Logo5 from "../../assets/static/img/category/Glues-Sealants-Chemicals.jpg";
import Logo6 from "../../assets/static/img/category/Metalwork.jpg";
import Logo7 from "../../assets/static/img/category/Aggregates-Heavyside.jpg";
import Logo8 from "../../assets/static/img/category/Fixings.jpg";
import Logo9 from "../../assets/static/img/category/Concrete-Products.jpg";

const TopCategories = () => {
  const autoPartCategories = [
    {
      thumbnail: Logo1,
      title: "Softwood",
      links: [
        "Cheshire Mouldings",
        "Firrings/Arris/Featheredge/Fencing",
        "Thermowood",
        "Pellets",
      ],
      url: "/Softwood"
    },
    {
      thumbnail: Logo2,
      title: "MDF Mouldings",
      links: [
        "MDF Skirting & Archtrave",
        "MDF Window Boards",
        "MDF Door Stop & Misc",
        "MDF Linings Sets",
      ],
      url: "/MDF-Mouldings"
    },
    {
      thumbnail: Logo3,
      title: "Insulation",
      links: ["Celotex", "Fibreglass", "Cavity Insulation", "Polystyrene"],
      url: "/Insulation"
    },
    {
      thumbnail: Logo4,
      title: "Sheet Material",
      links: [
        "Birch Plywood",
        "Hardwood Throughout Plywood",
        "Hardwood Throughout Plywood- FSC",
        "Shuttering Plywood",
      ],
      url: "/Sheet-Material"
    },
    {
      thumbnail: Logo5,
      title: "Glues Sealants & Chemicals",
      links: ["CT1", "Evo-Stik", "Bond It", "OB1"],
      url: "/Glues-Sealants-Chemicals"
    },
    {
      thumbnail: Logo6,
      title: "Metalwork",
      links: [
        "Parkes Products Joist Hangers",
        "Parkes Products Jiffy Hangers",
        "Parkes Products Beading",
        "Ties",
      ],
      url: "/Metalwork"
    },
    {
      thumbnail: Logo7,
      title: "Aggregates & Heavyside",
      links: ["Bulk Bags", "Mini Bags", "Cement", "Top Soil"],
      url: "/Aggregates-Heavyside"
    },
    {
      thumbnail: Logo8,
      title: "Fixings",
      links: [
        "TIMco Firmahold",
        "TIMco Nails",
        "TIMco Nails",
        "TIMco Shield Protective Film",
      ],
      url: "/Fixings"
    },
    {
      thumbnail: Logo9,
      title: "Concrete Products",
      links: ["Pre-Stressed Lintels", "Copings", "Pier Caps", "Padstones"],
      url: "/Concrete-Products"
    },
  ];



  return (
    <div>
      <section className="ps-top-categories mt-50 pb-60 mobile-mt">
        <div className="container">
          <div className="ps-section__header">
            <h3>TOP CATEGORIES OF THE MONTH</h3>
          </div>
          <div className="ps-section__content"></div>
          <div className="row align-content-lg-stretch">
            {autoPartCategories.map((category ,i) => (
              <div className="col-md-4 col-sm-6 col-12" key={i}>
                <div
                  className="ps-block--category-2 ps-block--category-auto-part"
                  data-mh="categories"
                >
                  <div className="ps-block__thumbnail pl-15">
                    <img src={category.thumbnail} loading="lazy" alt="whanson" />
                  </div>
                  <div className="ps-block__content">
                    <h4>
                      <Link to={`${category.url}`} style={{ fontWeight: "600" }}>{category.title}
                      </Link></h4>
                    <ul>
                      {category.links &&
                        category.links.map((link,i) => (
                          <li key={i +''}>
                            <Link to={`${category.url}`} className="no-hover">
                              {link}
                            </Link>
                          </li>
                        ))}
                      <li className="more">
                        <Link to={`${category.url}`} className="no-hover">
                          More
                          <i className="icon-chevron-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section >
    </div >
  );
};

export default TopCategories;
