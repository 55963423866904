import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'


const CTASection = ({ image }) => {

    return (
        <div>
            <section className="ps-home-promotions">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <Link to="/spot-deals" className="ps-collection mb-30 mobile-mb" >
                                <img src={image?.spot_deal != undefined ? image?.spot_deal[0]?.image : ''} loading="lazy" alt="whanson" style={{ objectFit: "cover" }} />
                            </Link>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <Link to="/spot-deals-category" className="ps-collection" href="#">
                                <img src={image?.spot_deal != undefined ? image?.spot_deal[1]?.image : ''} loading="lazy" alt="whanson" style={{ objectFit: "cover" }} />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default CTASection