import React, { useEffect, useRef, useState } from "react"
import PageContainer from "../../components/layouts/PageContainer"
import BreadCrumb from "../../components/elements/BreadCrumb"
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace"
import HeaderMobile from "../../components/shared/headers/HeaderMobile"
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2"
import config from "../../config"
import axios from "axios"
import NoImg from "../../assets/static/img/w-logo.jpg"
import { useParams, Link, useNavigate } from "react-router-dom"
import LightBoxicon from "../category/LightBox"
import Loader from "../Loader"
import ReactPaginate from "react-paginate"
import { Helmet } from "react-helmet"

const SpotDeals = () => {
  const navigate = useNavigate()
  // useEffect(() => {
  //     if (!localStorage.getItem("auth_user")) {
  //         navigate("/login");
  //     }
  // }, []);

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Spot Deals",
      url: "/",
    },
  ]

  const params = useParams()

  //Views

  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  )
  const footer = <FooterMarketPlace2 />

  useEffect(() => {
    fetchDryWallProduc()
  }, [])

  const [isLoading, setLoading] = useState(false)
  const [product_list, setProductList] = useState([])
  const [allData, setAllData] = useState([])

  const fetchDryWallProduc = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("type", 2)
    try {
      await axios({
        method: "post",
        data: formData,
        url: `${config.apiUrl}/spotdeals`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        function customSort(a, b) {
          if (a.thumbnail_image === null || a.thumbnail_image === undefined) {
            return 1
          }
          if (b.thumbnail_image === null || b.thumbnail_image === undefined) {
            return -1
          }
          return 0
        }
        const sortedArray = res.data.data.sort(customSort)
        setProductList(sortedArray)
        setAllData(sortedArray)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onSortBy = e => {
    if (e.target.value == "") {
      setCalled(!isCalled)
      setProductList(product_list)
    } else if (e.target.value == "Newest") {
      setCalled(!isCalled)
      setProductList(product_list.sort((a, b) => b.id - a.id))
    } else if (e.target.value == "Oldest") {
      setCalled(!isCalled)
      setProductList(product_list.sort((a, b) => a.id - b.id))
    } else if (e.target.value == "Low-High") {
      setCalled(!isCalled)
      setProductList(product_list.sort((a, b) => a.price - b.price))
    } else if (e.target.value == "High-Low") {
      setCalled(!isCalled)
      setProductList(product_list.sort((a, b) => b.price - a.price))
    }
  }

  const [itemOffset, setItemOffset] = useState(0)
  const [currentItems, setCurrentItems] = useState([])
  const [pageCount, setPageCount] = useState(0)

  const [isCalled, setCalled] = useState(false)
  const [itemsPerPage, setitemsPerPage] = useState(30)
  useEffect(() => {
    setCurrentItems([])
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(product_list.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(product_list.length / itemsPerPage))
  }, [product_list, isCalled])

  const targetDivRef = useRef(null)

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    const targetDiv = targetDivRef.current
    targetDiv.scrollIntoView({
      top: 0,
      left: 0,
      behavior: "instant",
    })
    setCalled(!isCalled)
    const newOffset = (event.selected * itemsPerPage) % product_list.length
    setItemOffset(newOffset)
  }

  function applyDiscount(value, percentage) {
    let discountPercentage = percentage / 100
    let discountedValue = value - value * discountPercentage
    discountedValue = parseFloat(discountedValue * 100) / 100
    return parseFloat(discountedValue)?.toFixed(2)
  }

  return (
    <div>
      <Helmet>
        <title>Unbeatable Spot Deals on Top Brands at W-Hanson UK</title>
        <meta
          name="description"
          Content="Take advantage of special offers on renowned brands at W-Hanson UK. Visit our Spot aDeals page for exclusive savings and limited-time discounts."
        />
      </Helmet>
      {isLoading && <Loader />}
      <PageContainer header={headers} footer={footer} boxed={true}>
        <div className="ps-page--shop" ref={targetDivRef}>
          <BreadCrumb breacrumb={breadCrumb} />
          <div className="container">
            <div className="ps-layout--shop ps-shop--category">
              <div
                className="ps-layout__right col-12"
                style={{ maxWidth: "100%" }}
              >
                <h3
                  className="ps-shop__heading"
                  style={{ textTransform: "capitalize" }}
                >
                  Spot Deals Products
                  <span
                    className="text-danger mx-2"
                    style={{ fontSize: "12.5px" }}
                  ></span>
                </h3>
                <div className="ps-shopping pb-0">
                  <div className="ps-shopping__header">
                    <p>
                      <strong className="mr-2">{product_list?.length}</strong>
                      Products found
                    </p>
                    <div className="ps-shopping__actions">
                      <select
                        className="ps-select form-control ml-10"
                        data-placeholder="Sort Items"
                        onChange={onSortBy}
                      >
                        <option value="">Sort By</option>
                        <option value="Newest">Newest</option>
                        <option value="Oldest">Oldest</option>
                        <option value="Low-High">Price: Low to High</option>
                        <option value="High-Low">Price: High to Low</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    {currentItems.map((items, i) => {
                      const stars = []
                      for (let i = 1; i <= 5; i++) {
                        const className =
                          i <= items.rating ? "fa fa-star" : "fa fa-star-o"
                        stars.push(<i className={className} key={i}></i>)
                      }

                      let maxPrice = 0
                      let minPrice = 0
                      if (items?.stock?.length >= 1) {
                        minPrice = parseFloat(items?.stock[0]?.price).toFixed(2)
                        maxPrice = parseFloat(items?.stock[0]?.price).toFixed(2)
                      }

                      let Price
                      if (items?.stock?.length == 1) {
                        Price =
                          "£" + parseFloat(items?.stock[0]?.price).toFixed(2)
                      } else {
                        items?.stock?.forEach(item => {
                          const price = parseFloat(item.price).toFixed(2)
                          if (price < minPrice) {
                            minPrice = price
                          }
                          if (price > maxPrice) {
                            maxPrice = price
                          }
                        })
                        Price = "£" + minPrice + " - " + "£" + maxPrice
                      }

                      return (
                        <div key={i} className="col-md-3 pl-0 pr-0 big-resp">
                          <div
                            className={
                              items.name?.length > 130
                                ? "ps-product ps-product--inner all-product-card h-450"
                                : "ps-product ps-product--inner all-product-card h-340"
                            }
                          >
                            <div className="ps-product__thumbnail text-center">
                              <div
                                className="ps-product__badge"
                                style={{
                                  zIndex: "99",
                                }}
                              >
                                {items?.spot_deal} %
                              </div>
                              {items.thumbnail_image != null ? (
                                <Link to={`/product/${items.slug}`}>
                                  <img
                                    src={items.thumbnail_image}
                                    loading="lazy" alt="No img"
                                    height="171"
                                    style={{
                                      objectFit: "cover",
                                    }}
                                  />
                                </Link>
                              ) : (
                                <div
                                  style={{
                                    height: "171px",
                                  }}
                                >
                                  <Link to={`/product/${items.slug}`}>
                                    <img
                                      src={NoImg}
                                      loading="lazy" alt="No img"
                                      height="200"
                                      className="mt-15"
                                    />
                                  </Link>
                                </div>
                              )}

                              <LightBoxicon productId={items.id} />
                            </div>
                            <div className="ps-product__container">
                              <Link
                                to={`/product/${items.slug}`}
                                className="ps-product__vendor"
                                href="/shop"
                              >
                                {items?.name}
                              </Link>
                              <div className="ps-product__rating text-center ">
                                <span className="ps-rating">{stars}</span>
                              </div>
                              <div className="ps-product__content">
                                <div className="text-center price-fixed">
                                  <p
                                    className="ps-product__price sale"
                                    style={{
                                      fontSize: "16px",
                                    }}
                                  >
                                    <span
                                      className="text-black"
                                      style={{
                                        color: "black",
                                      }}
                                    >
                                      {" "}
                                      Price Range :
                                    </span>{" "}
                                    <br />
                                    {/* {Price} */}
                                    {/* {items?.stock?.length == 1 ? ( */}
                                      <s
                                        style={{
                                          paddingRight: "20px",
                                          textDecoration: "strike-out",
                                        }}
                                      >
                                        {Price}
                                      </s>
                                    {/* ) : null} */}
                                    {/* {items?.stock?.length == 1
                                      ?  */}
                                      £{applyDiscount(
                                        parseFloat(Price?.substring(1)),
                                        parseFloat(items?.spot_deal)
                                      )}
                                      {/* : Price} */}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="mt-30">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="Next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="Previous"
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"ant-pagination-item-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"ant-pagination-item-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"ant-pagination-item-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"ant-pagination-item-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  )
}

export default SpotDeals
