import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/layouts/PageContainer';
import BreadCrumb from '../../components/elements/BreadCrumb';
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers//FooterMarketPlace2';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import './BlogsDetails.css';

import config from '../../config';
import axios from 'axios';
import Loader from '../Loader';
import moment from 'moment';

const BlogDetails = () => {

    const [blog_details, setBlogDetails] = useState({})
    const [recentBlogs, setRecentBlogs] = useState([])
    const [loading, setLoading] = useState(false);

    const { slug } = useParams()
    // useEffect(() => {
    //     if (
    //         !localStorage.getItem("auth_user")
    //     ) {
    //         navigate("/login");
    //     }
    // }, []);

    useEffect(() => {
        // Select all tables inside div elements
        const tables = document.querySelectorAll('div table');
        if (tables) {
            // Loop through each table and wrap it inside a new div with the 'table-responsive' class
            tables.forEach(table => {
                // Create a new div element
                const wrapperDiv = document.createElement('div');
                wrapperDiv.classList.add('table-responsive');

                // Insert the new div before the table
                table.parentNode.insertBefore(wrapperDiv, table);

                // Move the table inside the new div
                wrapperDiv.appendChild(table);
            });
        }
    }, [blog_details])

    useEffect(() => {
        async function getBlogs() {
            try {
                setLoading(true)
                const response = await axios.get(`${config.apiUrl}/blogs/${slug}`)
                setBlogDetails(response.data?.blog)
                setRecentBlogs(response.data?.latest)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        getBlogs()
        window.scrollTo(0, 0)
    }, [slug])

    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Blogs",
            url: "/blogs",
        },
        {
            text: blog_details?.title,
        },
    ];


    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    return (
        <>
            {loading && <Loader />}
            <Helmet>
                <title>{blog_details?.title}</title>
                <meta name="description" Content={blog_details?.title} />
                <meta name="keywords" content="timber building materials, timber building materials near me,timber sheets" />
            </Helmet>

            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--single">
                    <BreadCrumb breacrumb={breadCrumb} />
                    <div className="ps-section--custom">
                        <div className="container">
                            <div className="blog-details-container d-md-flex">
                                <div className="blog-content">
                                    <div>
                                        <img src={blog_details?.imageUrl} loading="lazy" alt="companyImage" className='img-fluid' />
                                    </div>
                                    <h1 className="blog-title my-5">{blog_details?.title}</h1>
                                    <div className='editor_content' dangerouslySetInnerHTML={{ __html: blog_details?.description }} />
                                </div>

                                <div className="recent-blogs-sidebar mt-md-0 mt-5 pt-md-0 pt-5">
                                    <h3>Recent Blogs</h3>
                                    <div className="recent-blogs-list">
                                        {
                                            recentBlogs?.map((blog, i) => {
                                                return (
                                                    <div key={i} className='d-flex justify-content-between mb-4'>
                                                        {/* <img loading="lazy" alt="companyImage" /> */}
                                                        <div className='ms-3'>
                                                            <div>
                                                                <i className='fa fa-calendar text-secondary'></i> {moment(blog.created_at).format("DD, MMM YYYY")}</div>
                                                            <Link to={`/blog/${blog.slug}`}>{blog.title}</Link>

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            recentBlogs.length === 0 &&
                                            <div>No Recent Blogs...</div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>


        </ >
    )
}

export default BlogDetails