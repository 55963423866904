import React, { useEffect, useState } from "react";
import HomeDefaultDealOfDay from './HomeDefaultDealOfDay';
import PageContainer from "../../components/layouts/PageContainer";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import { Link, useNavigate } from "react-router-dom";
import Brand1 from "../../assets/static/img/brand-1.png"
import Brand2 from "../../assets/static/img/brand-2.png"
import Brand3 from "../../assets/static/img/brand-3.png" 
import Brand5 from "../../assets/static/img/brand-5.png"
import TopCategories from "./TopCategories";
import CTASection from "./CTASection";
import Banner1 from "../../assets/static/img/category/Banner (2).jpg"
import axios from "axios";
import config from "../../config";
import { Helmet } from "react-helmet";

const HomeMarketPlacePage = () => {

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("auth_user")
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);

  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  const [isLoading, setLoading] = useState(false);
  const [image, setImages] = useState([]);

  useEffect(() => {
    const fetchImage = async () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("type", 1);
      try {
        await axios({
          method: "post",
          data: formData,
          url: `${config.apiUrl}/offer_image`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          setImages(res.data.data);
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (image?.length == 0) {
      fetchImage();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Top Timber Suppliers & Builders Merchants in London | W-Hanson</title>
        <meta
          name="description"
          Content="Searching for timber suppliers in London? W-Hanson is a leading London timber supplier and builders merchant near you, offering high-quality timber mouldings and construction materials. Shop now!"
        />
        <meta name="keywords" content="timber suppliers London, London timber suppliers, builders merchants near me, timber mouldings, timber and builders merchants near me, high-quality timber London" />
      </Helmet>
      <PageContainer header={headers} footer={footer} title="Home Market Place">
        <main id="homepage-3">
          <section className="mt-50 mobile-mt">
            <div className="container">
              {/* <div className="mb-5 py-2 head_line">
                <marquee behavior="" scrollAmount="10" direction="">** We are close from Friday, 20th December 2024, & Re-open on Thursday, 2nd January 2025. Orders will not be processed until we return on 2nd January 2025. **</marquee>
              </div> */}
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                  <Link to="/bespoke-machine-service" className="ps-collection mb-30 mobile-mb" >
                    <img src={Banner1} loading="lazy" alt="whanson" className="resp-banner" style={{ objectFit: "cover" }} />
                  </Link>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                  <Link to="/offers" className="ps-collection mb-30 mobile-mb" href="#">
                    <img src={image?.offer} loading="lazy" alt="whanson" style={{ objectFit: "cover" }} />
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <TopCategories />
          <CTASection image={image} />
          <HomeDefaultDealOfDay collectionSlug="deal-of-the-day" />

          <div className="pt-70 pb-50">
            <div className="ps-best-sale-brands ps-section--furniture pt-10">
              <div className="container">
                <div className="ps-section__header"><h3>BEST SELLER BRANDS</h3></div>
                <div className="ps-section__content">
                  <ul className="ps-image-list">
                    <li className={"px-4 py-5"} style={{minWidth: "25%"}}>
                      <a>
                        <img src={Brand1} loading="lazy" alt="No Img" width='200' />
                      </a>
                    </li>
                    <li className={"px-4 py-5"} style={{minWidth: "25%"}}>
                      <a>
                        <img src={Brand2} loading="lazy" alt="No Img" width='200' />
                      </a>
                    </li>
                    <li className={"px-4 py-5"} style={{minWidth: "25%"}}>
                      <a>
                        <img src={Brand3} loading="lazy" alt="No Img" width='200' />
                      </a>
                    </li>
                    {/* <li className={"px-4 py-5"}>
                      <a>
                        <img src={Brand4} loading="lazy" alt="No Img" />
                      </a>
                    </li> */}
                    <li className={"px-4 py-5"} style={{minWidth: "25%"}}>
                      <a>
                        <img src={Brand5} loading="lazy" alt="No Img" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </main>
      </PageContainer>
    </>
  );
};
export default HomeMarketPlacePage;
