import React, { useState } from 'react'
import MobileHeaderActions from './modules/MobileHeaderActions';
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../../../assets/static/img/w-hanson-logo-white.png";
import { Drawer, Menu } from 'antd';
import Wlogo from "../../../assets/static/img/w-logo.jpg"
import SubMenu from 'antd/lib/menu/SubMenu';
import axios from 'axios';
import Loader from '../../../pages/Loader';
import config from '../../../config';

const HeaderMobile = () => {

    const menuData = {
        menuPrimary: {
            menu_1: [
                {
                    text: "Softwood",
                    url: "/Softwood",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [

                        {
                            text: "Cheshire Mouldings",
                            url: "/cheshire-mouldings",
                        },
                        {
                            text: "Firrings /Arris/ Featheredge /Fencing",
                            url: "/Firrings-Arris-Featheredge-Fencing",
                        },
                        {
                            text: "Thermowood",
                            url: "/Thermowood",
                        },

                        {
                            text: "C24 Softwood Treated - PEFC",
                            url: "/C24-softwood-treated-PEFC",
                        },
                        {
                            text: "Home Grown Eased Edge KD Treated",
                            url: "/home-grown-eased-edge-kd-treated",
                        },
                        {
                            text: "CLS",
                            url: "/CLS"
                        },
                        {
                            text: "PAR Contract Softwood",
                            url: "/PAR-Contract-Softwood"
                        },
                        {
                            text: "PAR European Softwood",
                            url: "/PAR-European-Softwood"
                        },
                        {
                            text: "PAR European Softwood Mouldings",
                            url: "/PAR-European-Softwood-Mouldings"
                        },
                        {
                            text: "Pointed Pegs",
                            url: "/pointed-pegs"
                        },
                        {
                            text: "Sash Materials",
                            url: "/sash-materials"
                        },
                        {
                            text: "Shrinkwrapped Door Linings",
                            url: "/shrinkwrapped-door-linings"
                        }
                    ],
                },
                {
                    text: "MDF Mouldings",
                    url: "/MDF-Mouldings",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "MDF Skirting & Archtrave",
                            url: "/MDF-Skirting-Archtrave",
                        },
                        {
                            text: "MDF Window Boards",
                            url: "/MDF-Window-Boards",
                        },
                        {
                            text: "MDF Door Stop & Misc",
                            url: "/MDF-Door-Stop-Misc",
                        },
                        // {
                        //   text: "MDF Linings",
                        //   url: "/",
                        // },
                        {
                            text: "MDF Linings sets",
                            url: "/MDF-Linings-sets",
                        },
                    ],
                },
                {
                    text: "Insulation",
                    url: "/Insulation",
                    extraClass: "menu-item-has-children insulation",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "Celotex",
                            url: "/Celotex",
                        },
                        {
                            text: "Fibreglass",
                            url: "/Fibreglass",
                        },
                        {
                            text: "Cavity Insulation",
                            url: "/Cavity-Insulation",
                        },
                        {
                            text: "Polystyrene",
                            url: "/Polystyrene",
                        },
                    ],
                },
                {
                    text: "Sheet Material",
                    url: "/Sheet-Material",
                    current: "shop",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [

                        {
                            text: "Birch Plywood",
                            url: "/Birch-Plywood",
                        },
                        {
                            text: "Hardwood Throughout Plywood",
                            url: "/Hardwood-Throughout-Plywood",
                        },
                        {
                            text: "Hardwood Plywood- FSC",
                            url: "/Hardwood-Plywood-FSC",
                        },
                        {
                            text: "Shuttering Plywood",
                            url: "/Shuttering-Plywood",
                        },
                        {
                            text: "Structural Plywood",
                            url: "/Structural-Plywood",
                        },
                        {
                            text: "Marine Plywood",
                            url: "/Marine-Plywood",
                        },
                        {
                            text: "OSB",
                            url: "/OSB",
                        },
                        {
                            text: "MDF",
                            url: "/MDF",
                        },
                        {
                            text: "Moisture Resistant MDF",
                            url: "/Moisture-Resistant-MDF",
                        },
                        {
                            text: "Chipboard",
                            url: "/Chipboard",
                        },
                        {
                            text: "Hardboard",
                            url: "/Hardboard",
                        },
                        // {
                        //     text: "Decorative MDF",
                        //     url: "/Decorative-MDF",
                        // },

                        {
                            text: "Hardie Backer",
                            url: "/Hardie-Backer",
                        },
                        // {
                        //     text: "Versafire",
                        //     url: "/Versafire",
                        // },
                        {
                            text: "Iron On Edging",
                            url: "/Iron-On-Edging",
                        },
                        {
                            text: "Melamine Faced MDF",
                            url: "/Melamine-Faced-MDF",
                        },
                        {
                            text: "Flexcell",
                            url: "/Flexcell",
                        },
                        {
                            text: "Correx/ Protection",
                            url: "/Correx-Protection",
                        },
                    ],
                },
                {
                    text: "Glues Sealants & Chemicals",
                    url: "/Glues-Sealants-Chemicals",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "CT1",
                            url: "/CT1",
                        },
                        {
                            text: "Evo - Stik",
                            url: "/Evo-Stik",
                        },
                        {
                            text: "Bond It",
                            url: "/Bond-It",
                        },
                        {
                            text: "OB1",
                            url: "/OB1",
                        },
                    ],
                },
                {
                    text: "Metalwork",
                    url: "/Metalwork",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "Joist Hangers",
                            url: "/Joist-Hangers",
                        },
                        {
                            text: "Jiffy Hangers",
                            url: "/Jiffy-Hangers",
                        },
                        {
                            text: "Beading",
                            url: "/Beading",
                        },
                        {
                            text: "Ties",
                            url: "/Ties",
                        },
                        {
                            text: "Restraint Straps",
                            url: "/Restraint-Straps",
                        },
                        {
                            text: "Reinforcement Mesh",
                            url: "/Reinforcement-Mesh",
                        },
                        {
                            text: "Metalwork Misc",
                            url: "/Metalwork-Misc",
                        },
                    ],
                },
                {
                    text: "Aggregates & Heavyside",
                    url: "/Aggregates-Heavyside",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "Bulk Bags",
                            url: "/Bulk-Bags",
                        },
                        {
                            text: "Mini Bags",
                            url: "/Mini-Bags",
                        },
                        {
                            text: "Cement",
                            url: "/Cement",
                        },
                        {
                            text: "Top Soil",
                            url: "/Top-Soil",
                        },
                        {
                            text: "Air Bricks",
                            url: "/Air-Bricks",
                        },
                        {
                            text: "Aco Channel",
                            url: "/Aco-Channel",
                        },
                        // {
                        //     text: "Bricks",
                        //     url: "/bricks",
                        // },
                        // {
                        //     text: "Concrete Blocks",
                        //     url: "/concrete-blocks",
                        // },
                        // {
                        //     text: "Lightweight Blocks ",
                        //     url: "/lightweight-blocks",
                        // },
                    ],
                },
                {
                    text: "Fixings",
                    url: "/Fixings",
                    current: "shop",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [

                        {
                            text: "TIMco Firmahold",
                            url: "/TIMco-Firmahold",
                        },
                        {
                            text: "TIMco Nails",
                            url: "/TIMco-Nails",
                        },
                        {
                            text: "TIMco Screws",
                            url: "/TIMco-Screws",
                        },
                        {
                            text: "TIMco Shield Protective Film",
                            url: "/TIMco-Shield-Protective-Film",
                        },
                        {
                            text: "TIMco Cutting",
                            url: "/TIMco-Cutting",
                        },
                        {
                            text: "TIMco Drilling and Driver Bits",
                            url: "/TIMco-Drilling-and-Driver-Bits",
                        },
                        {
                            text: "TIMco Misc",
                            url: "/TIMco-Misc",
                        },
                        {
                            text: "Timco Bags",
                            url: "/Timco-Bags",
                        },
                        {
                            text: "Timco Coach Bolts",
                            url: "/Timco-Coach-Bolts",
                        },
                        {
                            text: "Samac Screws",
                            url: "/Samac-Screws",
                        },
                        {
                            text: "Drywall Screws",
                            url: "/Drywall-Screws",
                        },
                        {
                            text: "Studding",
                            url: "/Studding",
                        },

                        {
                            text: "Plasterboard/ Rawlplug Fixings",
                            url: "/Plasterboard-Rawlplug-Fixings",
                        },
                        {
                            text: "Masonry Nails",
                            url: "/Masonry-Nails",
                        },
                        {
                            text: "Misc Fixings & Ironmongery",
                            url: "/Misc-Fixings-Ironmongery",
                        },

                    ],
                },
                {
                    text: "Drywall Products",
                    url: "/Drywall-Products",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        // {
                        //   text: "Metal Stud & Track/ Suspended Ceiling",
                        //   url: "/",
                        // },
                        {
                            text: "Plasterboard",
                            url: "/Plasterboard",
                        },
                        {
                            text: "Plaster",
                            url: "/Plaster",
                        },
                    ],
                },
                {
                    text: "Concrete Products",
                    url: "/Concrete-Products",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "Pre-Stressed Lintels",
                            url: "/Pre-Stressed-Lintels",
                        },
                        {
                            text: "Copings",
                            url: "/Copings",
                        },
                        {
                            text: "Pier Caps",
                            url: "/Pier-Caps",
                        },
                        {
                            text: "Padstones",
                            url: "/Padstones",
                        },
                        {
                            text: "Concrete Misc",
                            url: "/Concrete-Misc",
                        },
                    ],
                },
            ],
        },
    };

    const [open, setOpen] = useState(false);

    const items = JSON.parse(localStorage.getItem('items'))
    const totalQty = items?.reduce((acc, item) => acc + parseFloat(item.price), 0);

    function slugify(str) {
        return str
            .replace(/ &/g, '')
            .replace(' -', '')
            .replace(/\s+/g, '-') // Replace spaces with hyphens
            .replace(/\//g, '')
    }

    const [isLoading, setLoading] = useState(false)

    const [serach, setSerach] = useState("");
    const navigate = useNavigate()
    function handleSubmit(e) {
        e.preventDefault();
        navigate(`/search?keyword=${serach}`);
    }

    const [data, setData] = useState([])

    const onOpenDrawer = async () => {
        if (data?.length == 0) {


            setOpen(true)
            setLoading(true);
            try {
                await axios({
                    method: "get",
                    url: `${config.apiUrl}/categories`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                }).then((res) => {
                    setData(res.data)
                    setLoading(false);
                });
            } catch (error) {
                setLoading(false);
                console.log(error)
            }
        }
    }


    return (
        <div>
            <header className="header header--mobile">
                <div className="navigation--mobile align-items-center">
                    <div className="navigation__left">
                        <Link to="/">
                            <a className="ps-logo" >
                                <img
                                    src={Logo}
                                    loading="lazy" alt="Whanson"
                                    width='100'
                                />
                            </a>
                        </Link>
                    </div>
                    <div className="navigation__right">
                        {/* <a className="header__extra color-white" style={{ width: "16px" }} onClick={(e) => setSearch(!search)} >
                            <i className="icon-magnifier" style={{ fontSize: "18px" }}></i>
                        </a> */}
                        <div className="ps-cart--mini">
                            <a className="header__extra color-white mobile" >
                                <i className="icon-bag2 "></i>
                                <span>
                                    <i>{items?.length > 0 ? items?.length : 0}</i>
                                </span>
                            </a>
                            <div>
                                <div className="ps-cart__content text-left mobile-cart">
                                    {items?.length > 0 ? (
                                        <>
                                            {items?.map((item, i) => {
                                                return (
                                                    <div className="ps-cart__items p-4" key={i}>
                                                        <div className="ps-product--cart-mobile mb-0">
                                                            <div className="ps-product__thumbnail">
                                                                <Link to={`/product/${item.slug}`}>
                                                                    <img
                                                                        src={item.thumbnail_image != null ? item.thumbnail_image : Wlogo}
                                                                        loading="lazy" alt="No Img"
                                                                    />
                                                                </Link>
                                                            </div>
                                                            <div className="ps-product__content">
                                                                <Link className="ps-product__title text-black" to={`/product/${item.slug}`} style={{ color: "black" }} >
                                                                    {item.name}
                                                                </Link>
                                                                <p className="varition-size"> H : {item.height}mm , W : {item.width}mm , L : {item.length}m </p>
                                                                <p>
                                                                    <small>£{item.price}</small>
                                                                </p>{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className="ps-cart__footer">
                                                <h3 className="">
                                                    Sub Total:
                                                    <strong className="color-blue">£{parseFloat(totalQty).toFixed(2)}</strong>
                                                </h3>
                                                <figure className="justify-content-center">
                                                    <Link
                                                        to="/shopping-cart"
                                                        className="btn-ligth-blue w-50 text-center mr-10"
                                                    >
                                                        View Cart
                                                    </Link>
                                                    {!localStorage.getItem("auth_id") &&
                                                        !localStorage.getItem("auth_name") ? null : (
                                                        <a
                                                            className="btn-blue text-center w-50 ml-10"
                                                            onClick={() => setOpen(true)}
                                                        >
                                                            Checkout
                                                        </a>
                                                    )}
                                                </figure>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="ps-cart__items p-4" >
                                            <p className="mb-0">No products in cart</p>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                        <Link to="/users/login" className="header__extra color-white mobile mr-0" >
                            <i className="icon-user "></i>
                        </Link>
                        <a className="header__extra color-white mobile" onClick={() => onOpenDrawer()} >
                            <i className="icon-menu "></i>
                        </a>
                    </div>
                </div>
                {/* {search && (
                    <div className='search-mobile p-3'>
                        <form className="ps-form--search-mobile" onSubmit={handleSubmit}>
                            <div className="form-group--nest">
                                <input className="form-control" type="text" placeholder="Search something..." onChange={(e) => setSerach(e.target.value)} />
                                <button

                                >
                                    <i className="icon-magnifier"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                )} */}
            </header>
            <div className='search-mobile psearch-4 mobile-pb-0 mobil-search mobile-mb'>
                <form className="ps-form--search-mobile" onSubmit={handleSubmit}>
                    <div className="form-group--nest">
                        <input className="form-control" type="text" placeholder="Search something..." onChange={(e) => setSerach(e.target.value)} style={{ border: "1px solid #07395c" }} />
                        <button
                            style={{ backgroundColor: "#07395c" }}
                        >
                            <i className="icon-magnifier"></i>
                        </button>
                    </div>
                </form>
            </div>
            <Drawer title="Categories" placement="right" className='mobile-drawer' onClose={() => setOpen(false)} open={open}>
                {isLoading && (<Loader />)}
                <Menu
                    mode="inline"
                    className="menu--mobile-2"
                >
                    {data?.map((obj, i) => {
                        return (
                            <SubMenu title={
                                <Link to={`/${obj.slug}`} onClick={() => setOpen(false)} >{obj.name}</Link>
                            } key={i}>
                                {obj.sucategories?.map((sub, i) => {
                                    return (
                                        <Menu.Item key={i + sub.name}>
                                            <Link to={`/${sub.slug}`} onClick={() => setOpen(false)}>{sub.name}</Link>
                                        </Menu.Item>

                                    )
                                })}
                            </SubMenu>
                        )
                    })}
                    {/* {menuData.menuPrimary.menu_1.map((obj, i) => {
                        return (
                            <SubMenu title={
                                <Link to={`/${slugify(obj.text)}`} onClick={() => setOpen(false)} >{obj.text}</Link>
                            } key={i}>
                                {obj.subMenu?.map((sub, i) => {
                                    return (
                                        <Menu.Item key={i + sub.text}>
                                            <Link to={`/${slugify(sub.text)}`} onClick={() => setOpen(false)}>{sub.text}</Link>
                                        </Menu.Item>

                                    )
                                })}
                            </SubMenu>
                        )
                    })} */}

                </Menu>
            </Drawer>

        </div >
    )
}

export default HeaderMobile